import React, { Suspense } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import ErrorBoundary from './ErrorBoundary';
import Wrapper from './Wrapper';
import theme from './theme';

const graphEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const client = new ApolloClient({
  uri: graphEndpoint,
  cache: new InMemoryCache(),
});

const routes = [
  {
    Component: React.lazy(() => import('./pages/HomePage')),
    path: '/',
  },
  {
    Component: React.lazy(() => import('./pages/AboutPage')),
    path: '/about',
  },
  {
    Component: React.lazy(() => import('./pages/PodcastPage')),
    path: '/podcast',
  },
  {
    Component: () => <Navigate to="/" />,
    path: '*',
  },
];

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster toastOptions={{ error: { duration: 6000 }, success: { duration: 3000 }, duration: 3000 }} />
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Wrapper>
              <Routes>
                {routes.map(({ path, Component }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Suspense key={path} fallback={<div>Loading...</div>}>
                        <Component />
                      </Suspense>
                    }
                  />
                ))}
              </Routes>
            </Wrapper>
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
